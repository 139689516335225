import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { ValidationService } from '@app/core/services/validation.service';
import { DocumentService } from '@app/modules/document/services/document.service';
import { PrescriptionService } from '@app/modules/document/services/prescription.service';
import { PatientsService } from '@app/modules/patients/services/patients.service';
import { PetPatientService } from '@app/modules/patients/services/pet-patient.service';
import { UserService } from '@app/modules/user/user.service';
import { Patient } from '@app/shared/models';
import { ColorSetting } from '@app/shared/models/color-setting';
import { User } from '@app/shared/models/decodedLoginToken';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { RennovaService } from '@app/shared/services/rennova.service';
import { StateService } from '@app/shared/services/state.service';
import { markFormGroup } from '@app/utils/markFormGroup';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalResendDocumentoEmptyComponent } from '../modal-resend-documento-empty/modal-resend-documento-empty.component';
import { ModalSucessSendDocumentComponent } from '../modal-sucess-send-document/modal-sucess-send-document.component';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { environment } from '@env/environment';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-modal-send-document',
  templateUrl: './modal-send-document.component.html',
  styleUrls: ['./modal-send-document.component.scss']
})
export class ModalSendDocumentComponent implements OnInit {
  @Input() colorSetting: ColorSetting;
  @Output() closeModal = new EventEmitter<any>();

  tplModal: NzModalRef;

  @ViewChild('tplTitle')
  tplTitle: TemplateRef<{}>;

  @ViewChild('tplContent', { static: true })
  tplContent: TemplateRef<{}>;

  @ViewChild('tplFooter', { static: true })
  tplFooter: TemplateRef<{}>;

  title: string;
  code: string;
  loading = false;
  resend = false;
  sendDocumentRequired = false;
  showDownloadSignedPdf = true;
  doNotShowModalSuccessSendDocument = false;

  cellphoneStateInitial: string;
  emailStateinitial: string;

  private patient: Patient;

  sendForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private bsModalService: BsModalService,
    private patientService: PatientsService,
    private petPatientService: PetPatientService,
    private authService: AuthService,
    private prescriptionService: PrescriptionService,
    private notification: AppToastService,
    private userService: UserService,
    private documentService: DocumentService,
    private stateService: StateService,
    private router: Router,
    private rennovaService: RennovaService,
    private deviceService: DeviceDetectorService
  ) {
    this.sendForm = this.fb.group(
      {
        name: [''],
        emailChecked: [false],
        smsChecked: [false],
        whatsappChecked: [false],
        patientId: [''],
        sms: ['', Validators.compose([ValidationService.celularValidator])],
        email: ['', Validators.email],
        whatsapp: ['', Validators.compose([ValidationService.celularValidator])]
      },
      { validators: [Validators.compose([this.requiredEmail, this.requiredSms, this.requiredWhatsapp])] }
    );
  }

  ngOnInit() {
    if (!this.isRennova) {
      this.listenFields();
    }
  }

  get userLogin(): User {
    return this.authService.user();
  }

  get isRennova() {
    return this.stateService.data && this.stateService.data.isRennova && this.codeIsRennova();
  }

  get emailChecked() {
    return this.sendForm.get('emailChecked').value;
  }

  get smsChecked() {
    return this.sendForm.get('smsChecked').value;
  }

  get whatsappChecked() {
    return this.sendForm.get('whatsappChecked').value;
  }

  get email() {
    return this.sendForm.get('email');
  }

  get sms() {
    return this.sendForm.get('sms');
  }

  get whatsapp() {
    return this.sendForm.get('whatsapp');
  }

  get linkAcesso() {
    if (environment.appRedirectV2Enabled) {
      return `${environment.appV2UrlValidationDocument}/${this.code}`;
    }
    return `${window.location.origin}/p/${this.code}`;
  }

  requiredEmail(control: FormControl): { [key: string]: boolean } | null {
    if (!control.get('emailChecked').value) {
      control.get('email').setErrors(null);
    } else if (!control.get('email').value) {
      control.get('email').setErrors({ requiredEmail: true });
    }
    return null;
  }

  requiredSms(control: FormControl): { [key: string]: boolean } | null {
    if (!control.get('smsChecked').value) {
      control.get('sms').setErrors(null);
    } else if (!control.get('sms').value) {
      control.get('sms').setErrors({ requiredSms: true });
    }
    return null;
  }

  requiredWhatsapp(control: FormControl): { [key: string]: boolean } | null {
    if (!control.get('whatsappChecked').value) {
      control.get('whatsapp').setErrors(null);
    } else if (!control.get('whatsapp').value) {
      control.get('whatsapp').setErrors({ requiredWhatsapp: true });
    }
    return null;
  }

  copyLinkAccess() {
    navigator.clipboard.writeText(this.linkAcesso);
    this.notification.notify('success', 'Sucesso', 'Link de acesso copiado!');
  }

  @nativeAsync
  async createTplModal(
    code: string,
    patientId: string,
    {
      title = '',
      resend = false,
      sendDocumentRequired = false,
      showDownloadSignedPdf = true,
      isPet = false,
      colorSetting = null,
      doNotShowModalSuccessSendDocument = false
    } = {}
  ) {
    this.code = code;
    this.resend = resend;
    this.sendDocumentRequired = sendDocumentRequired;
    this.showDownloadSignedPdf = showDownloadSignedPdf && this.deviceService.isDesktop();
    this.colorSetting = colorSetting;
    this.doNotShowModalSuccessSendDocument = doNotShowModalSuccessSendDocument;

    switch (code[0]) {
      case 'P':
      case 'I':
      case 'V':
        this.title = 'Receita salva com sucesso!';
        break;
      case 'A':
        this.title = 'Atestado salvo com sucesso!';
        break;
      case 'E':
        this.title = 'Pedido de exame salvo com sucesso!';
        break;
      case 'O':
        this.title = 'Documento de orientações salvo com sucesso!';
        break;
      default: {
        if (code.substring(0, 3) === 'REN') {
          this.title = 'Documento enviado com sucesso!';
        } else {
          this.title = 'Documento salvo com sucesso!';
        }
      }
    }

    if (title) {
      this.title = title;
    }

    this.create();

    if (isPet) {
      await this.getPet(patientId);
    } else {
      await this.getPatient(patientId);
    }
  }

  private create() {
    this.tplModal = this.modalService.create({
      nzContent: this.tplContent,
      nzFooter: this.tplFooter,
      nzClosable: false,
      nzWidth: 650,
      nzMaskClosable: false,
      nzClassName: 'modal-send-document',
      nzOnOk: () => {},
      nzOnCancel: () => {}
    });

    this.tplModal.afterOpen.subscribe(() => {
      const elements = document.getElementsByClassName('ant-modal-footer');
      if (elements && this.colorSetting) {
        elements[0].setAttribute('style', `background-color: ${this.colorSetting.footer}`);
      }
    });
  }
  @nativeAsync
  async getPatient(patientId: string) {
    try {
      this.loading = true;
      this.patient = await this.patientService.getPatient(patientId).toPromise();
      this.initForm(this.patient);
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
  }
  @nativeAsync
  async getPet(petId: string) {
    try {
      this.loading = true;
      const { responsible } = await this.petPatientService.getPetById(petId).toPromise();
      this.patient = responsible;
      if (this.patient) {
        this.initForm(this.patient);
      }
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
  }

  private initForm(patient: Patient) {
    this.emailStateinitial = patient.user && (patient.user.emailContact || patient.user.email);
    if (!this.emailStateinitial && patient.responsible && patient.responsible.user) {
      this.emailStateinitial = patient.responsible.user.emailContact || patient.responsible.user.email;
    }

    if (this.emailStateinitial) {
      this.sendForm.get('email').setValue(this.emailStateinitial);
      this.sendForm.get('emailChecked').setValue(!!this.emailStateinitial);
      this.sendForm.get('email').markAsDirty();
    }

    this.cellphoneStateInitial = patient.cellphone || patient.telephone;
    if (patient.responsible) {
      this.cellphoneStateInitial = patient.responsible.cellphone || patient.responsible.telephone;
    }
    this.sendForm.get('sms').setValue(this.cellphoneStateInitial);
    this.sendForm.get('smsChecked').setValue(!!this.cellphoneStateInitial);
    this.sendForm.get('sms').markAsDirty();
    this.sendForm.get('whatsapp').setValue(this.cellphoneStateInitial);
    this.sendForm.get('whatsappChecked').setValue(!!this.cellphoneStateInitial);
    this.sendForm.get('whatsapp').markAsDirty();
    this.sendForm.get('patientId').setValue(patient._id);
  }
  sendRennova() {
    this.sendEmailsRennova(this.code);
    this.showModalSuccessSendDocument();
    this.tplModal.close();
  }
  @nativeAsync
  async send() {
    markFormGroup(this.sendForm);
    try {
      if (this.sendForm.valid) {
        if (this.someFieldWasChecked) {
          this.loading = true;
          const data = {
            email: this.emailChecked ? this.sendForm.value.email : undefined,
            sms: this.smsChecked ? this.sendForm.value.sms : undefined,
            name: this.sendForm.value.name,
            code: this.code,
            patientId: this.sendForm.value.patientId,
            originHost: window.location.origin
          };
          await this.patientService.sendDocument(data).toPromise();

          if (this.whatsappChecked) {
            await this.openWhatsAppTab();
          }
        }

        if (this.resend) {
          this.showModalSuccessSendDocument();
        } else if (this.userLogin) {
          this.loading = true;
          const { doNotShowAgainModalSuccessPrescription } = await this.userService
            .getControllerUserUi(this.userLogin._id)
            .toPromise();

          if (!doNotShowAgainModalSuccessPrescription) {
            this.showModalSuccessSendDocument();
          }
        }
        this.loading = false;
      }

      this.close();
    } catch (error) {
      this.loading = false;
    }
  }

  get someFieldWasChecked(): boolean {
    return this.smsChecked || this.whatsappChecked || this.emailChecked;
  }

  async downloadPrescriptionPdf(code: string) {
    try {
      this.loading = true;
      await this.prescriptionService.downloadOrGeneratePdf(code);
      this.notification.notify('success', 'Sucesso', 'Download realizado com sucesso!');
    } catch (err) {
      console.error(err);
    }
    this.loading = false;
  }

  @nativeAsync
  private async openWhatsAppTab() {
    const documentText = this.getDocumentText(this.code);
    const doctorName = await this.getDoctorName();

    const labelCpf = this.code[0] === 'V' ? 'responsável' : 'paciente';

    const text = `Olá, ${this.patient.name}! ${documentText} ${doctorName}.
Clique no link e visualize o documento digitando os 4 primeiros dígitos do CPF do ${labelCpf}: ${this.linkAcesso}
Em caso de dúvidas, acesse o atendimento em receitadigital.com`;

    const whatsapp = this.sendForm.value.whatsapp;
    const phone = `55${whatsapp}`;
    const encodedText = encodeURIComponent(text);
    const url = `https://wa.me/${phone}?text=${encodedText}`;

    window.open(url, '_blank');
  }

  private getDocumentText(code: string) {
    switch (code[0]) {
      case 'P':
      case 'I':
        return 'Aqui está a sua *Receita Digital* enviada';
      case 'A':
        return 'Aqui está o seu *Atestado* enviado';
      case 'E':
        return 'Aqui está o seu *Pedido de Exame* enviado';
      case 'V':
        return this.getDocumentTextVet();
      case 'O':
      default:
        return 'Aqui está o seu *Documento* enviado';
    }
  }

  getDocumentTextVet() {
    switch (this.code.substring(0, 4)) {
      case 'VETE':
        return `Aqui está o pedido de *Exame(s)* do seu Pet enviado`;
      case 'VETA':
        return `Aqui está o *Atestado* do seu Pet enviado`;
      case 'VETO':
        return `Aqui está o *Documento* do seu Pet enviado`;
      default:
        return `Aqui está a *Receita Digital* do seu Pet enviada`;
    }
  }

  @nativeAsync
  private async getDoctorName() {
    if (this.userLogin) {
      if (this.userLogin.sex === 'F') {
        return `pela Dra. ${this.userLogin.name}`;
      }
      return `pelo Dr. ${this.userLogin.name}`;
    }

    const { healthProfessional } = await this.documentService.getDocument(this.code);
    if (healthProfessional.sex === 'F') {
      return `pela Dra. ${healthProfessional.name}`;
    }
    return `pelo Dr. ${healthProfessional.name}`;
  }

  private showModalSuccessSendDocument() {
    if (this.doNotShowModalSuccessSendDocument) {
      return;
    }
    const viewSucessMsg =
      (this.emailChecked && this.email.value) ||
      (this.smsChecked && this.sms.value) ||
      (this.whatsappChecked && this.whatsapp.value);

    if (!viewSucessMsg && this.resend) {
      const initialState = { code: this.code };
      const modal = this.bsModalService.show(ModalResendDocumentoEmptyComponent, {
        initialState,
        backdrop: 'static',
        keyboard: false
      });
      modal.content.afterInit.subscribe(() => {
        this.close();
      });

      modal.content.goBack.subscribe(() => {
        this.create();
      });
    } else {
      const initialState = {
        code: this.code,
        resend: this.resend,
        colorSetting: this.colorSetting,
        isSuccessMsg: viewSucessMsg,
        patientId: this.patient._id
      };
      const modal = this.bsModalService.show(ModalSucessSendDocumentComponent, {
        initialState,
        backdrop: 'static',
        keyboard: false
      });

      modal.content.closeModal.subscribe(() => {
        this.close(true);
        const route = this.router.url.split('?')[0];
        this.router.navigate([route]);
      });
    }
  }

  confirmClose() {
    if (this.resend) {
      this.close();
    } else {
      this.modalService.confirm({
        nzTitle: 'Atenção',
        nzContent: 'Tem certeza que deseja cancelar o envio do documento?',
        nzOkText: 'Sim',
        nzCancelText: 'Não',
        nzOnOk: () => this.close()
      });
    }
  }

  close(sent?: boolean) {
    this.tplModal.destroy();
    this.closeModal.emit(sent);
  }

  private listenFields() {
    this.sendForm.get('emailChecked').valueChanges.subscribe(checked => {
      if (!checked) {
        this.sendForm.get('email').setValue(this.emailStateinitial);
      }
    });

    this.sendForm.get('smsChecked').valueChanges.subscribe(checked => {
      if (!checked) {
        this.sendForm.get('sms').setValue(this.cellphoneStateInitial);
      }
    });

    this.sendForm.get('whatsappChecked').valueChanges.subscribe(checked => {
      if (!checked) {
        this.sendForm.get('whatsapp').setValue(this.cellphoneStateInitial);
      }
    });
  }

  private sendEmailsRennova(code: string) {
    this.rennovaService.sendDocument({ code }).subscribe();
  }

  codeIsRennova() {
    return this.code && this.code.substring(0, 3) === 'REN';
  }
}
