export const environment = {
  production: true,
  local: false,
  environment: 'prod',
  apiRoot: 'https://api.receitadigital.com/api/',
  apiV1: 'https://api.receitadigital.com/v1',
  host: 'https://app.receitadigital.com/',
  plusGApp: 'https://assine.gosigner.com.br',
  envPlusSecretKey: '637e2ffee279a02ff6d1ccec224d01db',
  envPlusSecretKeyIS: 'a5e82ce673fe7007f96fb2c2b7fce055',
  envKeySpecialty: 'fr1MzCU8RC3YPIX8prW9EalrEfoYiWqi8afdVbDk',
  envKeyMedicament: 'wBwNQLTg2t3bTFWjNhgZ01iFEnJC3Mu05D4G23IE',
  envAdminApiKey: 'RfMlZ8UB8GiRkdfeN4c5COCL58ldtauB',
  debounceTime: 300,
  maintenance: false,
  template: '',
  versionCheckURL: 'https://app.receitadigital.com/version.json',
  tempoDeValidadeDaSessao: 240,
  appRedirectV2Enabled: true,
  appV2UrlValidationDocument: 'https://app2.receitadigital.com/d/v',
  envCmSereneApiKey: 'HIFJDEFD49490440EI2B1H9C1C4DFBJ2'
};
