import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CrService } from '@app/modules/document/services/cr.service';
import { ColorSetting } from '@app/shared/models/color-setting';
import { Library, Protocol } from '@app/shared/models/library';
import { LibraryService } from '@app/shared/services/library.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-protocols-library',
  templateUrl: './modal-protocols-library.component.html',
  styleUrls: ['./modal-protocols-library.component.scss']
})
export class ModalProtocolsLibraryComponent implements OnInit {
  @Input() colorSetting: ColorSetting;

  @Output() onAddProtocol = new EventEmitter<Protocol>();

  loadingLibraries = false;
  libraries: Library[] = [];

  constructor(
    private modalRef: BsModalRef,
    private libraryService: LibraryService,
    private crService: CrService
  ) {}

  ngOnInit(): void {
    this.findAllLibraries();
  }

  findAllLibraries() {
    this.loadingLibraries = true;
    this.libraryService.search().subscribe(libraries => {
      this.libraries = libraries;
      this.loadingLibraries = false;
    });
  }

  async expandedProtocol(protocol: Protocol) {
    protocol.expanded = !protocol.expanded;
    if (!protocol.medicamentCR) {
      await this.loadMedicamentCR(protocol);
    }
  }

  async loadMedicamentCR(protocol: Protocol) {
    protocol.loadingMedicament = true;
    const medicamentsCR = await this.crService.searchMedicament(protocol.ean).toPromise();
    if (medicamentsCR.length > 0) {
      const medicamentCR = medicamentsCR[0];
      protocol.medicamentCR = {
        nome: medicamentCR.nome,
        prescriptionTypeId: medicamentCR.prescriptionTypeId,
        typeCr: medicamentCR.typeCr,
        type: 'industrialized',
        laboratorio: medicamentCR.laboratorio,
        apresentacaoCustomizada: medicamentCR.apresentacaoCustomizada,
        principio: medicamentCR.principio
      };
      protocol.loadingMedicament = false;
    }
  }

  async addProtocol(protocol: Protocol) {
    if (!protocol.medicamentCR) {
      await this.loadMedicamentCR(protocol);
    }
    this.onAddProtocol.emit(protocol);
  }

  get categories() {
    if (this.libraries.length > 0) {
      return this.libraries[0].categories;
    }
    return [];
  }

  close() {
    this.modalRef.hide();
  }
}
